// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}._1GrU7hFPdZq0Jefl7-KCKN{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:102;max-width:600px;background:#fff;border:1px solid #eaeaea;border-radius:6px;box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);text-align:center}._1GrU7hFPdZq0Jefl7-KCKN ._2dXbxdth-86yBBcgAtckdK{display:flex;flex-direction:column;font-size:1.3rem;padding:40px 30px}._1GrU7hFPdZq0Jefl7-KCKN ._2dXbxdth-86yBBcgAtckdK span{margin:3px 0}._1GrU7hFPdZq0Jefl7-KCKN ._2dXbxdth-86yBBcgAtckdK span._3YKgn57Nqt-Pd-n95bME1U{font-weight:700}._1GrU7hFPdZq0Jefl7-KCKN ._2dXbxdth-86yBBcgAtckdK ._3-GEcdFSSh5ao-1NNErktu{text-align:center;margin-top:20px}._1GrU7hFPdZq0Jefl7-KCKN ._1hcwiIVEUckOdkhmA1_qnF{display:flex;padding:40px 30px;font-family:Arial,Helvetica,sans-serif;font-size:2.3rem;color:#5b599c}._1GrU7hFPdZq0Jefl7-KCKN ._1hcwiIVEUckOdkhmA1_qnF i{color:#c8c8c8;margin-right:22px}._1GrU7hFPdZq0Jefl7-KCKN ._1hcwiIVEUckOdkhmA1_qnF span{font-weight:700}._3jwUPomiOzaNkJqqXSMkKY{position:absolute;left:0;top:0;width:100%;height:100vh;background:#fff;opacity:.7;z-index:101}", ""]);
// Exports
exports.locals = {
	"dialog": "_1GrU7hFPdZq0Jefl7-KCKN",
	"deleteConfirm": "_2dXbxdth-86yBBcgAtckdK",
	"bold": "_3YKgn57Nqt-Pd-n95bME1U",
	"command": "_3-GEcdFSSh5ao-1NNErktu",
	"saved": "_1hcwiIVEUckOdkhmA1_qnF",
	"dialogOverlay": "_3jwUPomiOzaNkJqqXSMkKY"
};
module.exports = exports;
