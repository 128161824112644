// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}.LNpH8M-qBPMZVum4NkGhC{height:100vh;font-family:\"Roboto\",arial,sans-serif !important}.LNpH8M-qBPMZVum4NkGhC ._3TSH3k1o_PiwhYZKe4OwuR{font-size:1rem;font-weight:bold;margin-bottom:18px;color:#928dfc}.p4fQpArZYUi_EBbuneElf{display:flex;align-items:center;background:#5b599c;color:#fff;padding:9px 24px}.p4fQpArZYUi_EBbuneElf .UhB8IaoL4B5WgpEW_rKKK{font-family:\"Tangerine\",arial,sans-serif;font-size:32px;font-weight:600;flex-grow:1;transition:color 1s}.p4fQpArZYUi_EBbuneElf ._3oBjeHYh7VzobQpUF90ABV{font-size:2rem;color:#ceccfc;font-weight:700;letter-spacing:.3rem}._2jWMzAkrLyQ6ZtSpljFiou{width:300px;padding-top:156px;margin:0 auto}._2jWMzAkrLyQ6ZtSpljFiou button{margin-left:0}._2jWMzAkrLyQ6ZtSpljFiou input{width:272px;margin-bottom:15px}._2jWMzAkrLyQ6ZtSpljFiou div{margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"newsletter": "LNpH8M-qBPMZVum4NkGhC",
	"message": "_3TSH3k1o_PiwhYZKe4OwuR",
	"newsletter-header": "p4fQpArZYUi_EBbuneElf",
	"logo": "UhB8IaoL4B5WgpEW_rKKK",
	"title": "_3oBjeHYh7VzobQpUF90ABV",
	"unsubscribe": "_2jWMzAkrLyQ6ZtSpljFiou"
};
module.exports = exports;
