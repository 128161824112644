// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}.HO8j6juJp5peVXj_xhDAc{height:100vh;font-family:\"Roboto\",arial,sans-serif !important}.HO8j6juJp5peVXj_xhDAc ._3_vHqx-E9vP9117rAoGJKt{display:flex;align-items:center;height:60px;box-sizing:border-box;background:#5b599c;color:#fff;padding:9px 24px}.HO8j6juJp5peVXj_xhDAc ._3_vHqx-E9vP9117rAoGJKt ._3U3yEW_ffqsXK2JObJJoXK{font-family:\"Tangerine\",arial,sans-serif;font-size:32px;font-weight:600;flex-grow:1;transition:color 1s;cursor:pointer}.QToV7pF34H-ffkjPMRKIZ{display:flex;flex-direction:column}.QToV7pF34H-ffkjPMRKIZ ._1MA90sM_9JusUwlYecWEM-{display:flex;height:calc(100vh - 110px)}.QToV7pF34H-ffkjPMRKIZ ._1MA90sM_9JusUwlYecWEM- ._1YLFVhkHPgWsCYpaAVQEQn{height:100%;border-right:1px solid #eaeaea}.QToV7pF34H-ffkjPMRKIZ ._1MA90sM_9JusUwlYecWEM- ._3FNSpkHNEwCDtPp319cqaD{flex-grow:1;height:100%;padding:24px}", ""]);
// Exports
exports.locals = {
	"admin": "HO8j6juJp5peVXj_xhDAc",
	"header": "_3_vHqx-E9vP9117rAoGJKt",
	"logo": "_3U3yEW_ffqsXK2JObJJoXK",
	"content": "QToV7pF34H-ffkjPMRKIZ",
	"body": "_1MA90sM_9JusUwlYecWEM-",
	"leftSide": "_1YLFVhkHPgWsCYpaAVQEQn",
	"rightSide": "_3FNSpkHNEwCDtPp319cqaD"
};
module.exports = exports;
