import React, { FC, useState } from 'react'

const styles = require('./body.sass')

import {
  ColumnResizer,
  ColumnResizerTarget,
  IColumnResizerBoundary,
} from "../"


export function getLSWidth() {
  const stored = localStorage['nti_ls_width']
  return stored ? parseInt(stored) : 320
}

interface IProps {
  isLoading: boolean
  listJSX: JSX.Element
  formJSX: JSX.Element
  savedJSX: JSX.Element
  deleteConfirmJSX: JSX.Element
  onLSWidthChange: (width: number) => void
}

export const AdminBody: FC<IProps> = props => {
  const [_LSWidth, _setLSWidth] = useState(getLSWidth())

  function _columnResizerDragStop() {
    const el = document.getElementById('left-side')
    if (!el) { return }

    const w: any = el.style.width
    if (!w) { return }

    const width = parseInt(w.substring(0, w.length - 2))

    localStorage['nti_ls_width'] = width

    _setLSWidth(width)
    props.onLSWidthChange(width)
  }

  function _getColumnResizerBoundary(): IColumnResizerBoundary {
    return { min: 260, max: 520 };
  }

  return (
    <>
      <div className={styles.body}>
        <div id='left-side' style={{ width: _LSWidth }} className={styles.leftSide}>
          {props.isLoading ? LoaderJSX() : props.listJSX}
        </div>

        <ColumnResizer onDragStop={_columnResizerDragStop}
          target={ColumnResizerTarget.sibling}
          boundary={_getColumnResizerBoundary()} />

        <div className={styles.rightSide}>
          {props.formJSX != null ? props.formJSX : <EmptyJSX />}
        </div>
      </div>

      {props.savedJSX != null && props.savedJSX}
      {props.deleteConfirmJSX != null && props.deleteConfirmJSX}
    </>
  )
}

function LoaderJSX() {
  return (
    <div className={styles.loading}>
      <i className='fa fa-cog fa-spin' />
    </div>
  )
}

function EmptyJSX() {
  return (
    <div className={styles.empty}>
      <i className='fa fa-hand-pointer-o' />
    </div>
  )
}