import React, { FC, useState } from 'react'

const styles = require('./contacts.sass')

import { setItemTitle, ContactAction } from "../"
import { IContact } from "../../../services"

interface IProps {
  contact: IContact
  selected: boolean
  onClick: (action: ContactAction, contactID: number) => void
}

export const Item: FC<IProps> = props => {
  const [_hovering, _setHovering] = useState(false)

  function _click(action: ContactAction) {
    props.onClick(action, props.contact.id)
  }

  return (
    <div className={props.selected ? styles.itemSelected : styles.item}
      onMouseEnter={() => _setHovering(true)}
      onMouseLeave={() => _setHovering(false)}>

      <div className={styles.title} onClick={() => _click('select')}
        onMouseEnter={setItemTitle}>{props.contact.fullname}</div>
      {
        _hovering && <div className={styles.itemCommand}>
          <i className='fa fa-trash-o' title='delete contact'
            onClick={() => _click('delete')} />
        </div>
      }
    </div>
  )
}