import React, { FC, useState } from 'react'
import { useSpring, animated } from 'react-spring'

const styles = require('./dialog.sass')

interface IProps {
  onRest: () => void
}

export const Saved: FC<IProps> = ({ onRest }) => {
  const [toggle, setToggle] = useState(true)
  const [finished, setFinished] = useState(false)

  const top = (document.body.scrollHeight * .5)

  const props = useSpring({
    opacity: toggle ? 1 : 0, top: toggle ? top : document.body.scrollHeight - 200,
    from: { opacity: toggle ? 0 : 1, top: toggle ? 0 : top },
    config: { duration: 500 },
    onRest: () => {
      if (finished) {
        onRest()
      }
      else {
        setTimeout(() => { setToggle(!toggle), setFinished(true) }, 1000)
      }
    }
  })

  return (
    <animated.div className={styles.dialog} style={props}>
      <div className={styles.saved}>
        <i className='fa fa-thumbs-o-up' />
        <span>Saved!</span>
      </div>
    </animated.div>
  )
}