// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}button{font-family:\"Roboto\",Arial,sans-serif;font-size:.96rem;font-weight:600;color:#fff;padding:6px 12px;margin-left:6px;background-color:#16b;background:linear-gradient(#928dfc, #5b599c);border:1px solid #0b4075;border-radius:4px;cursor:pointer}button i{margin-right:6px}button span{font-size:.96rem !important;color:#fff !important}button:hover{border:1px solid #928dfc}button:focus{border:1px solid #928dfc;box-shadow:0 0 3px #928dfc;outline:none}.o8fEvLqLriWRPV3dmOQre{background-color:#858585;background:linear-gradient(#eaeaea, #707070);border:1px solid #272727}.o8fEvLqLriWRPV3dmOQre:hover{border:1px solid #858585}._1lAqrlPoPH5uiFrD7XuATo{background-color:#16b;background:linear-gradient(#5b599c, #928dfc);border:1px solid #928dfc}", ""]);
// Exports
exports.locals = {
	"neutral": "o8fEvLqLriWRPV3dmOQre",
	"selected": "_1lAqrlPoPH5uiFrD7XuATo"
};
module.exports = exports;
