import React, { useState, useEffect } from 'react'

const styles = require('./content.sass')

import { Command } from "./command/command"
import { List } from "./list/list";
import {
  AdminBody,
  getLSWidth,
  Saved,
  DeleteConfirm,
} from "../"

import {
  ContentSectForm,
  ContentBlockForm,
  IContentSectForm,
  IContentBlockForm,
  setContentSect,
  setContentBlock
} from '../forms'

import { Content as ContentSrv } from '../../../services/content'

const CS = new ContentSrv()

export type ContentAction =
  'addSect' | 'addBlock' |
  'delete' | 'deleteBlock' |
  'select' | 'selectBlock' |
  'moveUp' | 'moveDown' |
  'none'

export interface IContentActionData {
  action: ContentAction
  sectID: number
  sectTitle: string
  blockID: number
  blockTitle: string
  current: 'sect' | 'block' | 'none'
}

function getEmptyAction(): IContentActionData {
  return {
    action: 'none',
    blockID: 0,
    blockTitle: '',
    sectID: 0,
    sectTitle: '',
    current: 'none'
  }
}

interface IState {
  data: IContentActionData
  LSWidth: number
  isAdding: boolean
  isLoading: boolean
  showSaved: boolean
  showDeleteConfirm: boolean
}

export function Content() {
  const [_state, _setState] = useState<IState>({
    data: getEmptyAction(),
    LSWidth: getLSWidth(),
    isAdding: false,
    isLoading: true,
    showSaved: false,
    showDeleteConfirm: false,
  })

  useEffect(() => {
    CS.load().then(() => {
      _setState(prevState => ({ ...prevState, isLoading: false }))
    })
  }, [])

  function _action(data: IContentActionData) {
    console.log('action data:', data)
    switch (data.action) {
      case 'select':
        _setState(ps => ({ ...ps, data: data, isAdding: false }))
        break
      case 'selectBlock':
        _setState(ps => ({ ...ps, data: data, isAdding: false }))
        break
      case 'addSect':
        _setState(ps => ({ ...ps, data: data, isAdding: true }))
        break
      case 'addBlock':
        _setState(ps => ({ ...ps, data: data, isAdding: true }))
        break
      case 'delete':
      case 'deleteBlock':
        _setState(ps => ({ ...ps, data: data, showDeleteConfirm: true }))
        break
      case 'moveUp':
        _move('up', data)
        _setState(ps => ({ ...ps, data: data }))
        break
      case 'moveDown':
        _move('down', data)
        _setState(ps => ({ ...ps, data: data }))
        break
    }
  }

  function _move(dir: 'up' | 'down', data: IContentActionData) {
    const isBlock = data.blockID > 0
    const index = isBlock ?
      CS.findBlockIndex(data.sectID, data.blockID) :
      CS.findIndex(data.sectID)

    const sect = CS.find(data.sectID)

    const length = (isBlock ? sect.blocks.length : CS.get.length) - 1
    if (dir === 'up' && index === 0) return
    if (dir === 'down' && index === length) return

    const arr: any[] = isBlock ? sect.blocks : CS.get

    const to = dir === 'up' ? index - 1 : index + 1
    const from = arr.splice(index, 1)[0];
    arr.splice(to, 0, from);
  }

  function _saveSect(frm: IContentSectForm) {
    const data: IContentActionData = { ..._state.data, sectTitle: frm.title }

    if (_state.isAdding) {
      const id = CS.add(setContentSect(ContentSrv.EmptySect, frm))
      data.sectID = id
      data.action = 'select'
    }
    else {
      setContentSect(CS.find(data.sectID), frm)
      CS.save()
    }

    _setState(ps => ({ ...ps, data: data, isAdding: false, showSaved: true }))
  }

  function _saveBlock(frm: IContentBlockForm) {
    const data: IContentActionData = { ..._state.data, blockTitle: frm.title }

    if (_state.isAdding) {
      const block = setContentBlock(ContentSrv.EmptySectBlock, frm)
      const id = CS.addBlock(data.sectID, block)
      data.blockID = id
      data.action = 'selectBlock'
    }
    else {
      const block = CS.findBlock(data.sectID, data.blockID)
      setContentBlock(block, frm)
      CS.save()
    }

    _setState(ps => ({ ...ps, data: data, isAdding: false, showSaved: true }))
  }

  function _delete() {
    if (_state.data.blockID === 0) {
      CS.delete(_state.data.sectID)
    }
    else {
      CS.deleteBlock(_state.data.sectID, _state.data.blockID)
    }
    _cancel()
  }

  function _cancel() {
    _setState(prevState => ({
      ...prevState,
      data: getEmptyAction(),
      isAdding: false,
      showSaved: false,
      showDeleteConfirm: false,
    }))
  }

  function _listJSX() {
    return <List sects={CS.get} data={_state.data} onItemAction={_action} />
  }

  function _formJSX() {
    if (_state.data.current === 'none') return null
    return _state.data.current === 'sect' ?
      <ContentSectForm key={_state.data.sectID}
        sect={CS.find(_state.data.sectID)}
        isAdding={_state.isAdding}
        onSave={_saveSect}
        onDelete={() => _setState(ps => ({ ...ps, showDeleteConfirm: true }))}
        onCancel={_cancel} />
      :
      <ContentBlockForm key={_state.data.blockID}
        block={CS.findBlock(_state.data.sectID, _state.data.blockID)}
        isAdding={_state.isAdding}
        onSave={_saveBlock}
        onDelete={() => _setState(ps => ({ ...ps, showDeleteConfirm: true }))}
        onCancel={_cancel} />
  }

  function _deleteConfirmJSX() {
    if (!_state.showDeleteConfirm) return null
    return <DeleteConfirm item={_state.data.blockTitle === '' ? _state.data.sectTitle : _state.data.blockTitle}
      onConfirm={_delete} onCancel={() => _setState(ps => ({ ...ps, showDeleteConfirm: false }))} />
  }

  function _savedJSX() {
    if (!_state.showSaved) return null
    return <Saved onRest={() => _setState(ps => ({ ...ps, showSaved: true }))} />
  }

  return (
    <div className={styles.content}>
      <Command data={_state.data} LSWidth={_state.LSWidth} onCommand={_action} />
      <AdminBody isLoading={_state.isLoading}
        listJSX={_listJSX()}
        formJSX={_formJSX()}
        savedJSX={_savedJSX()}
        deleteConfirmJSX={_deleteConfirmJSX()}
        onLSWidthChange={(w) => _setState(ps => ({ ...ps, LSWidth: w }))} />
    </div>
  )
}