import React, { FC, useState, useEffect } from 'react'

const styles = require('./form.sass')

import { FormButtons, FormTitle } from "../"
import { IContact } from '../../../services'

export interface IContactForm {
  fullname: string
  firstname: string
  lastname: string
  email: string
  birthday: string
  comments: string
}

interface IProps {
  contact: IContact
  isAdding: boolean
  onSave: (frm: IContactForm) => void
  onDelete: () => void
  onCancel: () => void
}

export function setContact(contact: IContact, frm: IContactForm) {
  contact.fullname = frm.fullname
  contact.firstname = frm.firstname
  contact.lastname = frm.lastname
  contact.email = frm.email
  contact.birthday = frm.birthday
  contact.comments = frm.comments
  return contact
}

export const ContactForm: FC<IProps> = props => {
  const contact = props.contact

  const [_state, _setState] = useState<IContactForm>({
    fullname: contact ? contact.fullname : '',
    firstname: contact ? contact.firstname : '',
    lastname: contact ? contact.lastname : '',
    email: contact ? contact.email : '',
    birthday: contact ? contact.birthday : '',
    comments: contact ? contact.comments : '',
  })

  useEffect(() => {
    const el = document.getElementById('fullname') as HTMLInputElement
    if (el) {
      el.focus()
      el.setSelectionRange(0, 0)
    }
  }, [])


  function _inputChange(e: any) {
    const el = e.target as HTMLInputElement;
    _setState(prevState => ({
      ...prevState, [el.name]: el.value
    }))
  };

  return (
    <div className={styles.form}>
      <div className={styles.body}>
        <label>
          Full Name
          <input type='text' name='fullname' id='fullname'
            value={_state.fullname} onChange={_inputChange} />
        </label>

        <label>
          First Name
          <input type='text' name='firstname'
            value={_state.firstname} onChange={_inputChange} />
        </label>
        <label>
          Last Name
          <input type='text' name='lastname'
            value={_state.lastname} onChange={_inputChange} />
        </label>
        <label>
          Email
          <input type='text' name='email'
            value={_state.email} onChange={_inputChange} />
        </label>
        <label>
          Birthday
          <input type='text' name='birthday'
            value={_state.birthday} onChange={_inputChange} />
        </label>
        <label>
          Comments
          <textarea name='comments' rows={6}
            value={_state.comments} onChange={_inputChange} />
        </label>
      </div>
      <div className={styles.foot}>
        <FormButtons isAdding={props.isAdding}
          onSave={() => props.onSave(_state)}
          onDelete={props.onDelete}
          onCancel={props.onCancel} />
      </div>
    </div >
  )
}



