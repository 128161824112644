// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2zZMCvA48Pi9tkwXE0_WUP{padding:32px 0;background:#424874;color:#fff;font-family:\"Roboto\",Arial,Helvetica,sans-serif;font-size:1.6rem;font-weight:400;text-align:left;line-height:2.4rem}._2zZMCvA48Pi9tkwXE0_WUP div{display:flex;flex-direction:column;text-align:center !important}._2zZMCvA48Pi9tkwXE0_WUP ._1hivJifBbuZ1b2dkKCGRQe{text-decoration:none;color:#fff}._2zZMCvA48Pi9tkwXE0_WUP ._3bp7bcobJNPNRFw_oOy75Y{margin:12px 0;font-size:.96rem;line-height:1.2rem}", ""]);
// Exports
exports.locals = {
	"footer": "_2zZMCvA48Pi9tkwXE0_WUP",
	"link": "_1hivJifBbuZ1b2dkKCGRQe",
	"smallTxt": "_3bp7bcobJNPNRFw_oOy75Y"
};
module.exports = exports;
