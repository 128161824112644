// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}.mECfltjsZdWllcLI2F24C{height:100vh;font-family:\"Roboto\",arial,sans-serif !important}.mECfltjsZdWllcLI2F24C ._3VUIx0v1aYawnVgF8wQHV1{display:flex;align-items:center;height:60px;box-sizing:border-box;background:#5b599c;color:#fff;padding:9px 24px}.mECfltjsZdWllcLI2F24C ._3VUIx0v1aYawnVgF8wQHV1 .Dq0Jogq6He6BEHv1VyM73{font-family:\"Tangerine\",arial,sans-serif;font-size:32px;font-weight:600;flex-grow:1;transition:color 1s;cursor:pointer}._3eNZDxPnWrGPtUtAU9_s6a{margin-left:-2px;margin-right:-2px;width:4px;position:relative;background:transparent;cursor:col-resize;z-index:3;height:calc(100vh - 110px);flex:0 0 auto}._3eNZDxPnWrGPtUtAU9_s6a ._3paA0RJSklE46hMCZvtmOz{position:absolute;background:#eaeaea;cursor:col-resize}", ""]);
// Exports
exports.locals = {
	"admin": "mECfltjsZdWllcLI2F24C",
	"header": "_3VUIx0v1aYawnVgF8wQHV1",
	"logo": "Dq0Jogq6He6BEHv1VyM73",
	"columnResizer": "_3eNZDxPnWrGPtUtAU9_s6a",
	"mouseDown": "_3paA0RJSklE46hMCZvtmOz"
};
module.exports = exports;
