import React, { FC, useState, useEffect } from 'react'

const styles = require('./form.sass')

import { FormButtons, FormTitle } from "../"
import { Content as ContentSrv, IBlock, BlockType } from '../../../services'

export interface IContentBlockForm {
  type: BlockType
  title: string
  iconName: string
  linkID: string
  content: string
}

interface IProps {
  block: IBlock
  isAdding: boolean
  onSave: (frm: IContentBlockForm) => void
  onDelete: () => void
  onCancel: () => void
}

export function setContentBlock(block: IBlock, frm: IContentBlockForm) {
  block.title = frm.type === 'icon' || frm.type === 'paragraph' || frm.type === 'text-only' ?
    frm.content.substr(0, 50) : frm.title
  block.type = frm.type
  block.iconName = frm.iconName
  block.linkID = frm.linkID
  block.content = frm.content
  return block
}

export const ContentBlockForm: FC<IProps> = props => {
  const block = props.block || ContentSrv.EmptySectBlock

  const [_state, _setState] = useState<IContentBlockForm>({
    type: block.type,
    title: block.title,
    iconName: block.iconName,
    linkID: block.linkID,
    content: block.content,
  })

  useEffect(() => {
    const el = document.getElementById('title') as HTMLInputElement
    if (el) {
      el.focus()
      el.setSelectionRange(0, 0)
    }

    if (props.isAdding) {
      _setState(ContentSrv.EmptySectBlock)
    }
  }, [props.isAdding])

  function _inputChange(e: any) {
    const el = e.target as HTMLInputElement;
    _setState(prevState => ({
      ...prevState, [el.name]: el.value
    }))
  }

  function _selectChange(e: any) {
    const el = e.target as HTMLSelectElement
    _setState(prevState => ({
      ...prevState, [el.name]: el.value
    }))
  }

  function _getForm() {
    switch (_state.type) {
      case 'default':
        return <Default title={_state.title} content={_state.content}
          isAdding={props.isAdding} onChange={_inputChange} />
      case 'icon':
        return <Icon iconName={_state.iconName} content={_state.content} onChange={_inputChange} />
      case 'linkExpand':
        return <LinkExpand linkID={_state.linkID}
          title={_state.title} content={_state.content} onChange={_inputChange} />
      case 'linkToSect':
        return <LinkToSect linkID={_state.linkID} title={_state.title} onChange={_inputChange} />
      case 'paragraph':
      case 'text-only':
        return <Content content={_state.content} onChange={_inputChange} />
      case 'title':
        return <Title title={_state.title} isAdding={props.isAdding} onChange={_inputChange} />
    }
  }

  return (
    <div className={styles.form}>
      <div className={styles.body}>
        <label>
          Type
          <select name='type' value={_state.type} onChange={_selectChange} style={{ width: 300 }}>
            <option value='default'>Default</option>
            <option value='icon'>Icon</option>
            <option value='title'>Title</option>
            <option value='linkToSect'>Link to Section</option>
            <option value='linkExpand'>Link Expand</option>
            <option value='paragraph'>Paragraph</option>
            <option value='text-only'>Text Only</option>
          </select>
        </label>

        {_getForm()}

      </div>
      <div className={styles.foot}>
        <FormButtons isAdding={props.isAdding}
          onSave={() => props.onSave(_state)}
          onDelete={props.onDelete}
          onCancel={props.onCancel} />
      </div>
    </div >
  )
}

function Default({ title, content, isAdding, onChange }) {
  return (<>
    <label>
      <FormTitle title={title} isAdding={isAdding} />
      <input type='text' name='title' id='title'
        value={title} onChange={onChange} />
    </label>
    <Content content={content} onChange={onChange} />
  </>)
}

function Icon({ iconName, content, onChange }) {
  if (iconName === '') iconName = 'chevron-circle-right'
  return (<>
    <label>
      <span>Icon Name <a target='_blank'
        style={{ fontSize: '.8rem', marginLeft: 6 }}
        href='https://fontawesome.com/v4.7.0/icons/'>font awesome</a>
      </span>
      <input type='text' name='iconName'
        value={iconName} onChange={onChange} />
    </label>
    <Content content={content} onChange={onChange} />
  </>)
}

function Title({ title, onChange, isAdding }) {
  return (
    <label>
      <FormTitle title={title} isAdding={isAdding} />
      <input type='text' name='title' id='title'
        value={title} onChange={onChange} />
    </label>
  )
}

function LinkToSect({ linkID, title, onChange }) {
  return (<>
    <label>
      LinkID
      <input type='text' name='linkID' value={linkID} onChange={onChange} />
    </label>
    <label>
      Link Text
      <input type='text' name='title' value={title} onChange={onChange} />
    </label>
  </>)
}

function LinkExpand({ linkID, title, content, onChange }) {
  return (<>
    <label>
      LinkID
      <input type='text' name='linkID' value={linkID} onChange={onChange} />
    </label>
    <label>
      Link Text
      <input type='text' name='title' value={title} onChange={onChange} />
    </label>
    <Content content={content} onChange={onChange} />
  </>)
}

function Content({ content, onChange }) {
  return (
    <label>
      Content
      <textarea name='content' rows={24}
        value={content} onChange={onChange} />
    </label>
  )
}