import React, { FC, useState } from 'react'
import { useSpring, animated } from 'react-spring'

const styles = require('./dialog.sass')

import { Button } from '../'

interface IProps {
  item: string
  onConfirm: () => void
  onCancel: () => void
}

export const DeleteConfirm: FC<IProps> = ({ item, onConfirm, onCancel }) => {
  const [toggle, setToggle] = useState(true)
  const [clickedButton, setClickedButton] = useState('')

  const overlay = useSpring({
    opacity: toggle ? .5 : 0,
    from: { opacity: toggle ? 0 : .5 },
    config: { duration: 400, },
  })

  const dialog = useSpring({
    opacity: toggle ? 1 : 0,
    from: { opacity: toggle ? 0 : 1, },
    config: { duration: 400 },
    onRest: () => {
      if (clickedButton === 'confirm') {
        onConfirm()
      }
      else if (clickedButton === 'cancel') {
        onCancel()
      }
    }
  })

  function buttonClick(title) {
    setToggle(false)
    setClickedButton(title)
  }

  return (
    <>
      <animated.div className={styles.dialog} style={dialog}>
        <div className={styles.deleteConfirm}>
          <span>Are your sure you want to delete </span>
          <span className={styles.bold}>{item.endsWith('?') ? item : item += '?'}</span>
          <div className={styles.command}>
            <Button title='Cancel' iconName='times' onClick={() => { buttonClick('cancel') }} />
            <Button title='Delete' iconName='trash-o' onClick={() => buttonClick('confirm')} />
          </div>
        </div>
      </animated.div>
      <animated.div className={styles.dialogOverlay} style={overlay} />
    </>
  )
}