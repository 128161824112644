import React, { FC, useState } from 'react';

const styles = require('./columnResizer.sass')

export interface IColumnResizerBoundary {
  min: number;
  max: number;
}

export enum ColumnResizerTarget {
  sibling,
  parent,
  parentContainer
}

interface IProps {
  target: ColumnResizerTarget; // the div to resize, assumes the one to the right doesn't have a set width
  boundary: IColumnResizerBoundary // relative to client window
  offsetLeft?: number; // any element width to the left of the 2 columns being split
  onDragStop: () => void;
}

export const ColumnResizer: FC<IProps> = (props) => {
  const [mouseDown, setMouseDown] = useState(false);

  function _getTarget(currentTarget: EventTarget & HTMLDivElement) {

    switch (props.target) {
      case ColumnResizerTarget.sibling:
        return currentTarget.previousSibling as HTMLDivElement;
      case ColumnResizerTarget.parent:
        return currentTarget.parentElement as HTMLDivElement;
      case ColumnResizerTarget.parentContainer:
        const parent = currentTarget.parentElement as HTMLDivElement;
        return parent.parentElement as HTMLDivElement;
    }
  }

  function _mouseDown(ev: React.MouseEvent<HTMLDivElement>) {
    ev.preventDefault();
    ev.stopPropagation();

    const handle = ev.currentTarget;
    handle.style.position = 'absolute'
    handle.style.backgroundColor = '#eaeaea'
    handle.style.cursor = 'col-resize'

    const target = _getTarget(handle)
    const current = {
      top: handle.offsetTop,
      left: handle.offsetLeft,
      height: handle.offsetHeight
    }

    handle.style.left = current.left + 'px';
    handle.style.top = current.top + 'px';
    handle.style.height = current.height + 'px';

    const drag = (ev: any) => {
      const pos = ev.clientX;
      //console.log(pos);
      if (pos >= props.boundary.min && pos <= props.boundary.max) {
        handle.style.left = pos + 'px';
        current.left = pos;
      }
    }

    const closeDrag = () => {
      //console.log('close ' + current.left)
      target.style.width = (current.left - (props.offsetLeft || 0)) + 'px'; // lhtoc width

      document.onmouseup = null;
      document.onmousemove = null;
      handle.style.left = '';
      handle.style.top = '';
      handle.style.position = 'relative'
      handle.style.backgroundColor = 'transparent'
      handle.style.cursor = 'col-resize'

      setMouseDown(false);
      props.onDragStop();
    }

    document.onmousemove = drag;
    document.onmouseup = closeDrag;

    setMouseDown(true);
  }

  return (
    <div className={styles.columnResizer} onMouseDown={_mouseDown} />
  );
}