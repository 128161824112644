import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import './animatedText.sass';

export function AnimatedText({ text }) {
  const [txtFill, setTxtFill] = useState('url(#grad1)');

  const props: any = useSpring({
    to: async (next: any, cancel: any) => {
      await next({ x: '100%' });
      await next({ x: '0%' });
    },
    from: { x: '0%' },
    delay: 500,
    config: { duration: 1800 },
    onRest: () => {
      setTxtFill('#5b599c');
    }
  });

  return (
    <svg viewBox="0 0 800 145">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset='0%' stopColor='#5b599c' stopOpacity='1' />
          <animated.stop offset={props.x} stopColor='black' stopOpacity='0' />
          <stop offset="100%" stopColor='#5b599c' stopOpacity='1' />
        </linearGradient>
      </defs>

      <text x='60' y='70%' fill={txtFill}>{text}</text>
    </svg>
  );
}


