import React, { FC, useState, useEffect } from 'react'

const styles = require('./contacts.sass')

import { IContact, Contacts as ContactSrv } from '../../../services'
import { AdminBody, Button, DeleteConfirm, Saved, Item, getLSWidth } from '../'
import { ContactForm, IContactForm, setContact } from "../forms";

const CS = new ContactSrv()

export type ContactAction = 'add' | 'delete' | 'select'

interface IState {
  contactID: number
  LSWidth: number
  isAdding: boolean
  isLoading: boolean
  showForm: boolean
  showSaved: boolean
  showDeleteConfirm: boolean
}

export function Contact() {
  const [_state, _setState] = useState<IState>({
    contactID: 0,
    LSWidth: getLSWidth(),
    isAdding: false,
    isLoading: true,
    showForm: false,
    showSaved: false,
    showDeleteConfirm: false
  })

  useEffect(() => {
    CS.load().then(() => {
      _setState(prevState => ({ ...prevState, isLoading: false }))
    })
  }, [])

  function _save(frm: IContactForm) {
    if (_state.isAdding) {
      const id = CS.add(setContact(ContactSrv.empty, frm))
      _setState(prevState => ({ ...prevState, isAdding: false, contactID: id }))
    }
    else {
      CS.save(setContact(CS.find(_state.contactID), frm))
      _setState(prevState => ({ ...prevState, isAdding: false }))
    }
  }

  function _delete() {
    CS.delete(_state.contactID)
    _cancel()
  }

  function _cancel() {
    _setState(prevState => ({
      ...prevState,
      contactID: 0,
      isAdding: false,
      showForm: false,
      showDeleteConfirm: false
    }))
  }

  function _itemClick(action: ContactAction, contactID?: number) {
    switch (action) {
      case 'select':
        _setState(ps => ({ ...ps, contactID: contactID, isAdding: false, showForm: true }))
        break;
      case 'add':
        _setState(ps => ({ ...ps, contactID: 0, isAdding: true, showForm: true }))
        break;
      case 'delete':
        _setState(ps => ({ ...ps, contactID: contactID, showDeleteConfirm: true }))
        break;
    }
  }

  function _listJSX() {
    return (
      <div className={styles.list}>
        {CS.get.map((contact, i) =>
          <Item key={i} contact={contact}
            selected={contact.id === _state.contactID}
            onClick={_itemClick} />)}
      </div>
    )
  }

  function _formJSX() {
    if (_state.contactID === 0 && !_state.isAdding) return null
    return <ContactForm key={_state.contactID}
      contact={CS.find(_state.contactID)}
      isAdding={_state.isAdding}
      onSave={_save}
      onDelete={() => _setState(ps => ({ ...ps, showDeleteConfirm: true }))}
      onCancel={_cancel} />
  }

  function _deleteConfirmJSX() {
    if (!_state.showDeleteConfirm) return null
    const contact = CS.find(_state.contactID)
    return <DeleteConfirm item={contact ? contact.fullname : ''}
      onConfirm={_delete} onCancel={() => _setState(ps => ({ ...ps, showDeleteConfirm: false }))} />
  }

  function _savedJSX() {
    if (!_state.showSaved) return null
    return <Saved onRest={() => _setState(ps => ({ ...ps, showSaved: true }))} />
  }

  return (
    <div className={styles.contact}>
      <Command isAdding={_state.isAdding}
        contactID={_state.contactID}
        LSWidth={_state.LSWidth}
        onCommand={() => _itemClick('add')} />
      <AdminBody isLoading={_state.isLoading}
        listJSX={_listJSX()}
        formJSX={_formJSX()}
        savedJSX={_savedJSX()}
        deleteConfirmJSX={_deleteConfirmJSX()}
        onLSWidthChange={(w) => _setState(ps => ({ ...ps, LSWidth: w }))} />
    </div>
  )
}

function Command({ isAdding, contactID, LSWidth, onCommand }) {
  const txt = isAdding ? 'Add Contact' : 'Edit Contact'

  return (
    <div className={styles.command}>
      <div style={{ width: LSWidth }} className={styles.leftSide}>
        <span style={{ flexGrow: 1 }} className={styles.text}>Contacts</span>
        <Button title='New Contact' iconName='plus' onClick={() => onCommand('add')} />
      </div>
      <div className={styles.rightSide}>
        {(contactID !== 0 || isAdding) && <span style={{ flexGrow: 1 }} className={styles.text}>{txt}</span>}
      </div>
    </div>
  )
}