import * as React from 'react'
import { render } from 'react-dom'
import App from './components/app'

// import Amplify from 'aws-amplify'

// Amplify.configure({
//   Auth: {
//     identityPoolId: 'us-west-2:dd0af313-2447-4905-b53f-a32ba76d7ff9', //REQUIRED - Amazon Cognito Identity Pool ID
//     region: 'us-west-2', // REQUIRED - Amazon Cognito Region
//     userPoolId: 'us-west-2_NAmbTWEes', //OPTIONAL - Amazon Cognito User Pool ID
//     userPoolWebClientId: '1698jvdodbr1uaou203fup8vv1', //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//     AWSS3: {
//       bucket: 'nurturingtheintuitive.com', //REQUIRED -  Amazon S3 bucket
//       region: 'us-west-2', //OPTIONAL -  Amazon service region
//     }
//   }
// })

render(
  <App />,
  document.getElementById('root'),
);
