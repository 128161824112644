import React, { useEffect, useState } from 'react'
import { useSpring } from "react-spring"
//import { PayPalButton } from "react-paypal-button-v2";

const styles = require('./content.sass')

import { Section } from "./section"
import { Content as ContentSrv } from "../../services"

const CS = new ContentSrv()

export function Content() {
  const [_isLoading, _setIsLoading] = useState(true)

  useEffect(() => {
    CS.load().then(() => _setIsLoading(false))
  }, [])

  const [_y, _setY] = useSpring(() => ({
    y: 0, onFrame: props => window.scroll(0, props.y)
  }));

  function _goToSect(id: string) {
    const sect = document.getElementById(id);
    _setY({ y: sect.offsetTop - 65 }) // header height
  }

  function _expand(ev: any, id: string) {
    ev.preventDefault()
    const el = document.getElementById(id)
    el.style.display = el.style.display === 'block' ? 'none' : 'block'
  }

  return (
    <div className={styles.content}>
      {
        CS.get.map((sect, i) =>
          <Section key={sect.id}
            sect={sect}
            hideBorder={i === 0}
            onGotoSect={_goToSect}
            onExpand={_expand} />
        )
      }
    </div>
  )
}


// function hold() {
//   return <>
//     <Section id='animal_communication' title='Animal Communication'>
//       <SectionItem title='What is Animal Communication?'>
//         You communicate verbally and non-verbally with your pets daily.  The type of animal communication I am
//         referring to is the kind that takes place over the telephone, between myself, you and your animal companion.
//         </SectionItem>

//       <SectionItem title='How does Animal Communication work?'>
//         <p>
//           Practitioners vary in style and method, but this process starts with me going into a light trance which is
//           essentially a state of deep relaxation.  Once I make a connection with the animal I am to work with,
//           information begins to arrive in the form of images, feelings, sensations and words.
//           After I receive the information I relay it back to you and if need be I interpret it.
//           Sometimes I may not know what the meaning of a particular image or symbol is, but I need to give it to you anyways.
//           People have told me that they became clear about the importance of that symbol at a later date.
//           </p>
//         <p>
//           At the beginning of your session, it is best to give just the animal’s name, species (dog, cat, horse, etc.)
//           as well as the first names of the significant others in the animal’s life.  Often the issue is emotion based and
//           linked to another pet or human.  Often times our pets are catalysts for our own personal growth and healing.
//           I am often directed to help humans during their pet's session.
//           When appropriate, and with permission from the human client, we can address these issues.
//           </p>

//         <p>I also incorporate Distance Healing in my sessions.  This process can help you and your pet move through
//         emotional and mental blocks. I talk you through the resistances I am seeing while
//         clearing and balancing the energy field.  You can continue practicing with these techniques after
//         your session which will help maintain you and your pet's well-being.
//           </p>

//       </SectionItem>

//       <SectionItem title='How do I know if Animal Communication is real?'>
//         During the session I encourage you to trust what your body tells you. Clues as to whether the session is working may include:
//         a resonance with the information, a sense of peace, feelings of  validation, or feelings of motivation.
//         You will also know if the information is valid by experiencing improvement in your situation afterwards.
//         If during the initial 5 -10 minutes of your session you do not like how I work, or if for some reason I am unable
//         to connect with you or your animal, we can end the session with no charge.
//         At any time within the session, if what I say does not make sense to you, please let me know.
//         I can always find a different way of communicating what I intuit.
//         </SectionItem>

//       <SectionItem title='Why would my pet or I need an Animal Communicator?'>
//         Some people book an appointment to ‘check in’ with their animal - essentially using it as a component in
//         their wellness plan.  Sometimes people want to connect with a pet after it has passed over.  Many people call when
//         their pet is experiencing abnormal behavior and/or physical issues.  In times such as these,
//         I request a client call me only after their veterinarian has ruled out a physical issue.
//         I do not diagnose or prescribe medications, but offer insight that you can then discuss with your pet's health
//         care provider when appropriate.  If you have an animal trainer that you work with, you might also find it
//         helpful to discuss your session with them if they are open to it.  Though we all connect with our animals
//         intuitively, when we are in distress our emotions can block our intuition.  Having an animal
//         communicator during these times can be very helpful.
//         </SectionItem>

//       <SectionItem title='Can I learn Animal Communication?'>
//         Yes.  The fact is, you are using intuition everyday of your life whether you are aware of it or not.
//         Many classes, books and tutors are available to assist you.  For more information check out your library,
//         booksellers, metaphysical and pet stores as well as the world wide web.  I offer workshops and mentorships as well.
//           If you or a group of friends are interested, please contact me by phone at <span>(509) 778-1585</span> or by email
//           at <a href='mailto:nurturingtheintuitive@gmail.com'>nurturingtheintuitive@gmail.com</a>
//       </SectionItem>

//       <SectionItem title='What do I need to bring to a Telephone Reading?'>
//         <h4><a onClick={() => _goToSect('telephone_sessions')}>How a Telephone Session Works</a></h4>
//         <h4><a onClick={() => _goToSect('rates')}>Rates</a></h4>
//         <h4>Gift certificates are available for this service.
//           They can be mailed to you or directly to the recipient.</h4>
//       </SectionItem>
//     </Section>

//     <Section id='intuitive_readings' title='Intuitive Readings'>
//       <SectionItem title='Why would I want to receive an Intuitive Reading?'>
//         People receive sessions for a variety of reasons.  Some want insight pertaining to
//         relationships, health and/or career.  Some want to connect with a loved one that has passed over.
//         Others are curious because they have never received a reading before and want to find out
//         what it is like.
//         </SectionItem>

//       <SectionItem title='How does an Intuitive Reading work?'>
//         <p>
//           Practitioners vary in style and method, but this process starts with me going into a light trance
//           which is essentially a state of deep relaxation.  Once I make a connection with you,
//           information begins to arrive in the form of images, feelings, sensations and words.
//           After I receive the information I relay it back to you and if need be I interpret it.
//           Sometimes I may not know what the meaning of a particular image or symbol is, but I give it to you anyways.
//           People have told me that they became clear about the importance of that symbol at a later date.
//           </p>
//         <p>
//           I also incorporate Distance Healing in my sessions.  This process can help you move through
//           emotional and mental blocks. I talk you through the resistances I am seeing while helping you to
//           clear and balance your energy field.  You can continue practicing with these techniques after
//           your session to help maintain your well-being.
//           </p>
//       </SectionItem>

//       <SectionItem title='What do I need to bring to a Telephone Reading?'>
//         <h4><a onClick={() => _goToSect('telephone_sessions')}>How a Telephone Session Works</a></h4>
//         <h4><a onClick={() => _goToSect('rates')}>Rates</a></h4>
//         <h4>Gift certificates are available for this service.
//             They can be mailed to you or directly to the recipient.</h4>
//       </SectionItem>
//     </Section>

//     <Section id='massage_and_bodywork' title='Massage and Bodywork'>

//       <SectionItem title='What are the benefits of massage for people and pets?'>
//         <div className='checkIconItem'>

//           If you have received a professional massage at your spa or health club, then you already know how good
//           bodywork feels and why it would be a good thing for your dog, cat or horse.  But those who have never received a
//           therapeutic massage might wonder why they or their pet would benefit from bodywork.  The fact is,
//           there are many physiological and psychological benefits for both us and our animal friends when receiving
//           regular bodywork. Along with proper nutrition, adequate exercise, socialization and regular medical care,
//           massage offers a holistic approach to the well-being of you and your pets.  It can increase the
//           circulation of blood and lymph, strengthen the immune system, assist in pain management,
//           calm the nervous system, help improve disposition and behavior, and help to improve posture,
//             mobility and range of motion. <a onClick={(ev) => _expand(ev, 'more-massage-and-bodywork')}>Click here to read more.</a>
//         </div>

//         <div id='more-massage-and-bodywork' style={{ display: 'none' }}>
//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               If you or your pet do not exercise much, massage can help offset the effects of a sedentary lifestyle.
//               This is because massage nourishes and cleanses the body’s cells, it increases circulation of the blood,
//               and it increases lymphatic flow.  Lymph is one of the body’s garbage collectors.  When someone receives
//               regular massage, it means that less long term waste accumulates inside of their body. This keeps the
//               immune system from working overtime. An immune system that works overtime on a consistent
//               basis is susceptible to weakening.  A healthy immune system can mean fewer medical visits and more
//               time for playing fetch.
//             </div>

//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               When we or our pet suffer from a chronic condition such as arthritis, regular massage can provide a
//               safe and natural alternative to pain relief by releasing endorphins - the body’s natural ‘feel good’
//               hormones.  Less physical pain can translate into less emotional stress for you and your animal companion.
//             </div>

//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               Massage can also help calm the nervous system.  By slowing the heart rate and lowering blood pressure,
//               massage fosters relaxation.  New additions to the family, geographic relocations and high stress events
//               in the household are especially important times to consider massage for you and your pet.
//               Some studies have shown massage is helpful for reducing anxiety, insomnia and digestive disorders.
//               Calmer nerves can translate into a more effective day at the office because your brain is working better.
//               It can also translate into better training sessions for you, your horse, and your dog.
//             </div>

//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               Athletes and weekend warriors, whether human, horse or dog, can especially benefit from massage.
//               Bodywork can speed up recovery time, improve posture and mobility by releasing contracted muscle
//               tissue, increase range of motion by breaking down adhesions and it is also known to increase the
//               production of synovial fluid - the body’s natural lubricant for joints.
//               Who does not have a better quality of life with optimal mobility?
//             </div>

//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               Poor behavior in our pet is often their way of acting out physical pain. Addressing this pain
//               will often change an animal’s attitude and therefore its actions.
//               In addition, massage has been proven to calm aggressive and nervous animals while instilling
//               confidence in timid ones.
//             </div>

//           <div className='checkIconItem'>
//             <i className="fa fa-superpowers"></i>
//               Though no one can guarantee longevity regardless of their health habits, why not err on the side of
//               caution and invest in the health and well-being of you and your animals?
//               It may mean a longer life with more opportunities to create joyful memories.
//             </div>
//         </div>
//       </SectionItem>

//       <SectionItem title='What types of massage do you offer?'>

//         <h4>I draw from a variety of massage techniques including but not limited to:</h4>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Integrative Reflexology®</span> for overall health and well-being through hand and foot massage.
//               This comprehensive modality integrates massage with Zone Therapy and with Traditional Chinese
//               Medicine’s 5 Element Theory.  The physical, emotional, mental and spiritual aspects of the client
//               can be addressed.  Sessions can accommodate for those who cannot lie down
//               and need to receive treatment in an upright or reclining position. This work is excellent for people
//               who have endured trauma, as it helps to ground individuals whose defense mechanisms may include
//               disassociation.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Swedish</span> for relaxation and overall health and well-being.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Deep Tissue</span> for breaking down both adhesions (scar tissue) and chronic tension in muscle layers.
//               Just because the pressure is deeper does not mean the work is painful.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Sports Massage</span> for recovery time between events.  Combined with Energy Balancing and Stretching it
//               can calm or energize an athlete pre and post event.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Trigger Point Therapy</span> for releasing contracted muscles by addressing the origination and insertion
//               points of muscles.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Cupping</span> for stimulating lymph and blood flow, as well as for breaking up adhesions in connective tissue.
//               This technique is applied in conjunction with regular massage and bodywork.
//               Soft silicone cups are gently pressed onto the body creating a vacuum - an inverse pressure.
//               It is this gentle lifting and suctioning action that helps create space in the layers of tissues
//               lying underneath the skin.  More space equates to improved movement of fluids such as water, blood and lymph.
//             </div>

//         <div className='checkIconItem'>
//           <i className="fa fa-superpowers"></i>
//           <span>Energy Balancing</span> for calming the nervous system. I draw from Therapeutic Touch, Polarity Therapy as well as     Acupressure to help balance the flow of energy through meridians. When I work with a human client,
//              they remain fully clothed while seated in a chair or lying comfortably on a bodywork table.
//           </div>

//       </SectionItem>

//       {/* <SectionItem title='Can I combine an Intuitive Consultation or Animal Communication session with bodywork?'>
//           Yes, I can incorporate Readings into Energy Balancing sessions if a client requests.
//         </SectionItem> */}

//       <SectionItem title='Where can I or my pet receive a bodywork session from you?'>
//         When working with horses, dogs and cats, I make farm, kennel and house calls.
//         I see human clients at my home office.
//         </SectionItem>

//       <SectionItem title='Can I learn pet massage?'>
//         Yes, I love teaching people how to work with their pets.  I do this in the context of your pet’s massage or by setting up a separate appointment specifically for your mentoring.  I also offer classes.
//           If you or a group of friends are interested, please call me at <span>(509) 778-1585</span> or email me
//             at <a href="mailto:nurturingtheintuitive@gmail.com">nurturingtheintuitive@gmail.com</a>
//       </SectionItem>

//       {/* <SectionItem title='Intake Forms'>
//           <h4><a onClick={() => console.log('click')}>Download Pet Massage Intake Form</a></h4>
//           <h4><a onClick={() => console.log('click')}>Download Human Massage Intake Form</a></h4>
//           <h4><a onClick={() => console.log('click')}>Download Hippa Compliance Form </a></h4>
//         </SectionItem> */}
//     </Section>

//     <Section id='telephone_sessions' title='Telephone Sessions'>

//       <SectionItem title='Before your session'>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             If you have questions, please write them out as concisely as you can.
//             Oftentimes, the more specific your question is, the more specific your answer will be.
//             You can also come to your session without questions.
//             If you prefer to have the emphasis of your session be on talking and
//             sharing your story, I recommend a Distance Healing session instead.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Relax, center yourself and create a quiet place free of distraction.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Bring paper and pen for taking notes.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Bring tissues in case of emotional clearing.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Be sure to have plenty of drinking water on hand.  I have found that more information comes
//             through when both myself and the client stays hydrated throughout the session.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             If you booked an Animal Communication appointment, your animal does not need to be with you at
//             the time of your session.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             By signing up for an appointment, you understand that I do not diagnose conditions or prescribe medicine
//             or supplements. Please seek appropriate medical assistance when needed.  Your session does not take
//             the place of a health provider.
//           </div>

//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             All sessions are confidential.  I cannot however, guarantee the security of any cellular service
//             provider you or I use.
//           </div>
//       </SectionItem>

//       <SectionItem title='During your session'>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             In the first few minutes of your session, I provide you with a series of initial images and
//             information.  For the rest of the session it is your time to ask questions you may have.  If you do not
//             have questions, then I go more deeply into the images and information I receive.
//           </div>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             If I am working with a pet, I ask that you provide me with its name and species.
//           </div>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>

//         </div>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             I find that people often have a better flow of information during their session when they sit in a
//             relaxed position with arms and legs uncrossed.
//           </div>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Sometimes emotions come up during a session.  If this happens it is best to breathe them through.
//           </div>
//       </SectionItem>

//       <SectionItem title='After your session'>
//         <div className='chevronCircleIconItem'>
//           <i className="fa fa-chevron-circle-right"></i>
//             Take some time by yourself to integrate.  Journaling and walking are two ways for you to
//             help the session sink in.
//           </div>
//       </SectionItem>
//     </Section>

//     <Section id='about' title='About'>

//       <div className='txtBlock'>
//         <p>My mission is to provide high quality, compassionate services that enhance
//         the physical, emotional, mental and spiritual health of people and pets.
//           </p>
//         <p><a onClick={(ev) => _expand(ev, 'more-about')}>Click here to read how I got started as an
//           Intuitive.</a></p>
//       </div>

//       <div id='more-about' style={{ display: 'none' }}>
//         <div className='txtBlock'>
//           I grew up hearing stories about the maternal side of my family having the gift of intuition.
//           My great grandmother Anna read tea leaves for the women in her small mining town in exchange
//           for butter and eggs -her actual crystal ball is still in possession by our family.
//           Great Aunt Mary used dreams to become financially successful in real estate. Whenever she dreamed of
//           fully risen bread loaves coming from the oven, she knew the transaction would be a profitable one.
//           Aunt Mary also read palms during family gatherings - I remember my own reading from her clearly.
//           My grandmother Anna, who I am named after, woke up one night, packed a suitcase and told my grandfather
//           to take her to the hospital.  She had dreamed my mom lost her first- born child.  Though my grandfather thought
//           she was crazy, it turned out to be an accurate dream.  My mom’s first child, Kathleen, had died right after birth.
//           I was fascinated by these stories and wanted to learn more.  Through a program at The Evergreen State College,
//           I took classes toward that end: intuitive reading and healing, massage, movement, energy work and dreams.
//           I remember thinking after taking a class on psychic reading, “I’ve been doing this my whole life.”
//           I had figured everyone processed information the way I did.  That experience made me realize this was not true.
//           In 1987, after I had begun my career as a massage therapist, a city bus hit me as I was riding my bike home
//           from work.  After healing from the trauma,  I began working with clients again.
//           That’s when I realized my intuition had amplified.  I was compelled to integrate it
//           into my work as a healer.  I also changed my last name to WhisperingHorse also as a result of this process.
//           The name came from my inner voice and it is a humbling and constant reminder to trust my intuition.
//           </div>
//       </div>
//     </Section>

//     <Section id='rates' title='Rates'>
//       <div className='txtBlock'>
//         A one hour session runs $55-85 based on a sliding scale.
//         Additional time is charged at $15 per 15 minute increment.
//         Massage sessions include time for consultation and dressing.
//         Travel time and out of country phone charges apply.
//         </div>

//       {/* <div style={{ textAlign: 'center' }}>
//            <PayPalButton amount='.01' />
//        </div> */}
//     </Section>
//   </>

//  }
