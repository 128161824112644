
import AWS from "aws-sdk"

// AWS.config.region = "us-west-2"
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: "us-west-2:dd0af313-2447-4905-b53f-a32ba76d7ff9",
// })

const BUCKET_NAME = "nurturingtheintuitive.com"
const sectsJson = require('./content.json')

export interface ISection {
  id: number
  title: string
  subtitle: string
  content: string
  blocks: IBlock[]
}

export type BlockType = 'default' | 'icon' | 'linkToSect' |
  'linkExpand' | 'paragraph' | 'text-only' | 'title'

export interface IBlock {
  id: number
  type: BlockType
  title: string
  iconName: string
  linkID: string
  content: string
}

export class Content {
  private _sects: ISection[] = []

  public async load() {
    try {
      this._sects = sectsJson
      //this._sects = await this._getFromJson()
      console.log('load', this._sects)
    }
    catch (err) {
      console.log('load error:', err)
    }
  }

  public get get(): ISection[] {
    return this._sects
  }

  public add(sect: ISection): number {
    const id = this._createSectID()
    sect.id = id
    this._sects.push(sect)
    this._saveToJSON()
    return id
  }

  public addBlock(sectID: number, block: IBlock): number {
    const sect = this.find(sectID)
    const id = this._createBlockID(sect)
    block.id = id
    sect.blocks.push(block)
    this._saveToJSON()
    return id
  }

  public save() {
    this._saveToJSON()
  }

  public delete(sectID: number) {
    const index = this.findIndex(sectID)
    if (index !== -1) {
      this._sects.splice(index, 1)
      this._saveToJSON()
    }
  }

  public deleteBlock(sectID: number, blockID: number) {
    const sect = this.find(sectID)
    const index = sect.blocks.findIndex(b => b.id === blockID)
    sect.blocks.splice(index, 1)
    this._saveToJSON()
  }

  public find(sectID: number) {
    return this._sects.find(sect => sect.id === sectID)
  }

  public findIndex(sectID: number) {
    return this._sects.findIndex(sect => sect.id === sectID)
  }

  public findBlock(sectID: number, blockID: number) {
    const sect = this.find(sectID)
    if (!sect) { return undefined }
    return sect.blocks.find(b => b.id === blockID)
  }

  public findBlockIndex(sectID: number, blockID: number) {
    const sect = this.find(sectID)
    if (!sect) { return undefined }
    return sect.blocks.findIndex(b => b.id === blockID)
  }

  public static get EmptySect(): ISection {
    return {
      id: 0,
      title: '',
      subtitle: '',
      content: '',
      blocks: []
    }
  }

  public static get EmptySectBlock(): IBlock {
    return {
      id: 0,
      type: 'default',
      title: '',
      iconName: '',
      linkID: '',
      content: '',
    }
  }

  private _createSectID() {
    if (this._sects.length === 0) { return 1; }
    let ids: number[] = [];
    this._sects.forEach(sect => ids.push(sect.id));
    return Math.max(...ids) + 1;
  }

  private _createBlockID(sect: ISection) {
    if (sect.blocks.length === 0) { return 1; }
    let ids: number[] = [];
    sect.blocks.forEach(item => ids.push(item.id));
    return Math.max(...ids) + 1;
  }

  private async _getFromJson() {
    try {
      const url = await this._getSignedUrl("content.json")
      //const url = await Storage.get('content.json') as string
      const resp: Response = await fetch(url)
      const json = await resp.text()
      //console.log('json:', json)
      return JSON.parse(json) as ISection[]
    } catch (err) {
      console.log('_getFromJson err:', err)
    }
  }

  private async _getSignedUrl(key: string) {
    const s3 = new AWS.S3()
    return s3.getSignedUrlPromise("getObject", {
      Bucket: BUCKET_NAME,
      Key: `public/${key}`
    })
  }

  private async _saveToJSON() {
    try {
      const json = JSON.stringify(this._sects)
      const s3 = new AWS.S3()
      await s3.upload({
        Bucket: BUCKET_NAME,
        Key: "public/content.json",
        Body: json,
        ContentType: "application/json"
      }).promise()

      //console.log('_saveToJSON:', json)
      //await Storage.put('content.json', json)
    } catch (err) {
      console.log('_saveToJSON err:', err)
    }
  }

}
