// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}@media screen and (max-width: 830px){h2._29QE71SOkpU9UhOzbLlmeF{font-size:2.2rem !important}}.W9Pi_bj85lhN6V_7_PHVb{margin-top:65px;padding-bottom:12px;text-align:center;background:#dcd6f7}.W9Pi_bj85lhN6V_7_PHVb img{margin:0 auto 24px 0;border:2px solid #cacfd6;box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);max-width:600px;width:100%;height:auto}.W9Pi_bj85lhN6V_7_PHVb h2._29QE71SOkpU9UhOzbLlmeF{font-family:\"Tangerine\",arial,sans-serif;font-size:3.2rem;font-weight:700;color:#5b599c;margin:0 0 24px 0}", ""]);
// Exports
exports.locals = {
	"mission": "_29QE71SOkpU9UhOzbLlmeF",
	"promo": "W9Pi_bj85lhN6V_7_PHVb"
};
module.exports = exports;
