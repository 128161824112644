import React, { FC } from 'react'

const styles = require('./command.sass')

import { Button, IContentActionData, ContentAction } from '../../'

interface IProps {
  data: IContentActionData
  LSWidth: number
  onCommand: (data: IContentActionData) => void
}

export const Command: FC<IProps> = ({ data, LSWidth, onCommand }) => {
  function _buttonClick(action: ContentAction) {
    if (action === 'addSect') {
      onCommand({
        action: action,
        sectID: 0,
        sectTitle: '',
        blockID: 0,
        blockTitle: '',
        current: 'sect'
      })
    }
    else {
      onCommand({
        action: action,
        sectID: data.sectID,
        sectTitle: data.sectTitle,
        blockID: 0,
        blockTitle: '',
        current: 'block'
      })
    }

  }

  function _getRightSideJSX() {
    if (data.action.startsWith('add')) {
      const txt = data.action === 'addSect' ? 'Add Section' : 'Add Block'
      return <span style={{ flexGrow: 1 }} className={styles.text}>{txt}</span>
    }
    else if (data.action === 'select') {
      return <>
        <span style={{ flexGrow: 1 }} className={styles.text}>Edit Section</span>
        <Button title='New Block' iconName='plus' onClick={() => _buttonClick('addBlock')} />
      </>
    }
    else if (data.action === 'selectBlock') {
      return <>
        <span style={{ flexGrow: 1 }} className={styles.text}>Edit Block</span>
        <Button title='New Block' iconName='plus' onClick={() => _buttonClick('addBlock')} />
      </>
    }
    else {
      return null
    }
  }

  return (
    <div className={styles.command}>
      <div style={{ width: LSWidth }} className={styles.leftSide}>
        <span style={{ flexGrow: 1 }} className={styles.text}>Content</span>
        <Button title='New Section' iconName='plus' onClick={() => _buttonClick('addSect')} />
      </div>
      <div className={styles.rightSide}>
        {_getRightSideJSX()}
      </div>
    </div>
  )
}