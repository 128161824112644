import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { hot } from 'react-hot-loader'
import { useSpring, animated } from 'react-spring'

import { Header, Promo, Content, Footer, Admin } from "../components"
import { Unsubscribe } from "../components/newsletter";
import "./app.sass"

function App() {
  const [promoKey, setPromoKey] = useState(0)

  const props = useSpring({
    opacity: 1, from: { opacity: 0 },
    config: { duration: 1000 }
  })

  function headerLogoClick() {
    setPromoKey(promoKey + 1)
  }

  return (
    <Router>
      <animated.div style={props}>
        <Switch>
          <Route exact path='/'>
            <Header onLogoClick={headerLogoClick} />
            <Promo key={promoKey} />
            <Content />
            <Footer />
          </Route>
          <Route path='/admin'>
            <Admin />
          </Route>
          <Route path='/unsubscribe'>
            <Unsubscribe />
          </Route>
        </Switch>
      </animated.div>
    </Router>
  )
}

declare let module: object
export default hot(module)(App)
