import React, { FC } from "react"

const styles = require('./content.sass')

import { ISection, IBlock } from '../../services'
import { parseContent } from './parseContent'

interface IBlockProps {
  block: IBlock
  onExpand?: (ev: any, id: string) => void
  onGotoSect?: (id: string) => void
}

interface ISectProps {
  sect: ISection
  hideBorder: boolean
  onGotoSect: (id: string) => void
  onExpand: (ev: any, id: string) => void
}

export const Section: FC<ISectProps> = ({ sect, hideBorder, onGotoSect, onExpand }) => {
  const id = sect.title.toLowerCase().replace(' ', '_')
  return (
    <div id={id} className={styles.section} style={hideBorder ? { borderTop: 'none' } : {}}>
      <h2>{sect.title}</h2>
      {sect.subtitle !== '' && <h2 style={{ fontSize: '4.2rem' }}>{sect.subtitle}</h2>}
      {sect.content !== '' && <div>{sect.content}</div>}
      {sect.blocks.map((block, i) => {
        switch (block.type) {
          case 'default':
            return <BlockDefault key={i} block={block} />
          case 'icon':
            return <BlockIcon key={i} block={block} />
          case 'linkToSect':
            return <BlockLinkToSect key={i} block={block} onGotoSect={onGotoSect} />
          case 'linkExpand':
            return <BlockLinkExpand key={i} block={block} onExpand={onExpand} />
          case 'paragraph':
            return <BlockParagraph key={i} block={block} />
          case 'text-only':
            return <BlockTextOnly key={i} block={block} />
          case 'title':
            return <BlockTitle key={i} block={block} />
        }
      })}
    </div>
  )
}

const BlockDefault: FC<IBlockProps> = ({ block }) => {
  return <>
    <h3>{block.title}</h3>
    {parseContent(block.content)}
  </>
}

const BlockIcon: FC<IBlockProps> = ({ block }) => {
  return (
    <div className={styles.icon}>
      <i className={'fa fa-' + block.iconName} />
      {parseContent(block.content)}
    </div>
  )
}

const BlockLinkToSect: FC<IBlockProps> = ({ block, onGotoSect }) => {
  return <h4><a onClick={() => onGotoSect(block.linkID)}>{block.title}</a></h4>
}

const BlockLinkExpand: FC<IBlockProps> = ({ block, onExpand }) => {
  return <>
    <a onClick={(ev) => onExpand(ev, block.linkID)}>{block.title}</a>
    <p id={block.linkID} style={{ display: 'none', marginTop: 24 }}>
      {parseContent(block.content)}
    </p>
  </>
}

const BlockParagraph: FC<IBlockProps> = ({ block }) => {
  return <p>{parseContent(block.content)}</p>
}

const BlockTextOnly: FC<IBlockProps> = ({ block }) => {
  return <div style={{ margin: '12px 0' }}>{parseContent(block.content)}</div>
}

const BlockTitle: FC<IBlockProps> = ({ block }) => {
  return <h3>{block.title}</h3>
}

