import AWS from "aws-sdk"

// AWS.config.region = "us-west-2"
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: "us-west-2:dd0af313-2447-4905-b53f-a32ba76d7ff9",
// })

const BUCKET_NAME = "nurturingtheintuitive.com"
const contactsJson = require('./contacts.json')

export interface IContact {
  id: number
  fullname: string
  firstname: string
  lastname: string
  email: string
  birthday: string
  comments: string
  created: string
  updated: string
}

export class Contacts {
  private _contacts: IContact[] = []

  public async load() {
    try {
      //this._contacts = await this._getFromJson()
      this._contacts = contactsJson
    }
    catch (err) {
      console.log('load error:', err)
    }
  }

  public get get(): IContact[] {
    return this._contacts
  }

  public add(contact: IContact): number {
    const id = this._createID()
    contact.id = id

    const d = new Date()
    contact.created = d.toUTCString()
    contact.updated = d.toUTCString()

    this._contacts.push(contact)
    this._saveToJSON()

    return id
  }

  public save(contact: IContact) {
    const d = new Date()
    contact.updated = d.toUTCString()
    this._saveToJSON()
  }

  public delete(id: number) {
    const index = this._contacts.findIndex(c => c.id === id)
    if (index !== -1) {
      this._contacts.splice(index, 1)
      this._saveToJSON()
    }
  }

  public deleteByEmail(email: string) {
    const index = this._contacts.findIndex(c => c.email === email)
    if (index !== -1) {
      this._contacts.splice(index, 1)
      this._saveToJSON()
    }
  }

  public static get empty(): IContact {
    const d = new Date()
    return {
      id: 0,
      fullname: '',
      firstname: '',
      lastname: '',
      email: '',
      birthday: '',
      comments: '',
      created: d.toUTCString(),
      updated: d.toUTCString(),
    }
  }

  public find(contactID: number) {
    return this._contacts.find(c => c.id === contactID)
  }

  public findIndex(contactID: number) {
    return this._contacts.findIndex(c => c.id === contactID)
  }

  public findByEmail(email: string) {
    return this._contacts.find(c => c.email === email)
  }

  private async _getFromJson() {
    try {
      const url = await this._getSignedUrl("contacts.json")
      const resp: Response = await fetch(url)
      const json = await resp.text()
      return JSON.parse(json) as IContact[]
    } catch (err) {
      console.log('_getFromJson err:', err)
    }
  }

  private async _getSignedUrl(key: string) {
    const s3 = new AWS.S3()
    return s3.getSignedUrlPromise("getObject", {
      Bucket: BUCKET_NAME,
      Key: `public/${key}`
    })
  }

  private async _saveToJSON() {
    try {
      const json = JSON.stringify(this._contacts)

      const s3 = new AWS.S3()
      await s3.upload({
        Bucket: BUCKET_NAME,
        Key: "public/contacts.json",
        Body: json,
        ContentType: "application/json"
      }).promise()

    } catch (err) {
      console.log('_saveToJSON err:', err)
    }
  }

  private _createID() {
    if (this._contacts.length === 0) { return 1; }
    let ids: number[] = [];
    this._contacts.forEach(sect => ids.push(sect.id));
    return Math.max(...ids) + 1;
  }
}