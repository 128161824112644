// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}._2-74tJnm2h21CXl64K9ATm{display:flex;align-items:center;background:#f4f4f4;height:50px;box-sizing:border-box;border-bottom:1px solid #eaeaea}._2-74tJnm2h21CXl64K9ATm .FaEYpBglH0NWPGCiny2tI{font-size:1.3rem;color:#5b599c}._2-74tJnm2h21CXl64K9ATm ._3dzMFeaJ5kRHS8zCC65y_q{display:flex;align-items:center;padding:0 12px 0 16px;box-sizing:border-box}._2-74tJnm2h21CXl64K9ATm .MB_H3yqTPfMILwnqDHWDb{display:flex;align-items:center;flex-grow:1;padding:0 24px 0 24px}", ""]);
// Exports
exports.locals = {
	"command": "_2-74tJnm2h21CXl64K9ATm",
	"text": "FaEYpBglH0NWPGCiny2tI",
	"leftSide": "_3dzMFeaJ5kRHS8zCC65y_q",
	"rightSide": "MB_H3yqTPfMILwnqDHWDb"
};
module.exports = exports;
