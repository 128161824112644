import React, { FC, useState } from 'react'

const styles = require('./list.sass')

import { SectItem, BlockItem, IContentActionData } from "../"
import { ISection } from '../../../../services';

export function setItemTitle(ev: any) {
  const el = ev.target as HTMLElement
  // check for ellipse
  el.title = el.offsetWidth < el.scrollWidth ? el.innerText : ''
}

interface IProps {
  sects: ISection[]
  data: IContentActionData
  onItemAction: (data: IContentActionData) => void
}

export const List: FC<IProps> = ({ sects, data, onItemAction }) => {
  return (
    <div className={styles.list}>
      {sects.map((sect, i) =>
        <SectItem key={i} sect={sect} data={data} onClick={onItemAction} />)}
    </div>
  )
}