import React, { FC, useState } from "react";

const styles = require('./button.sass')

interface IButton {
  title: string
  iconName?: string
  width?: number
  isNeutral?: boolean
  selected?: boolean
  onClick: () => void
}

export function Button<IButton>(props) {
  const { title, iconName, width, isNeutral, selected, onClick } = props

  // use a different color scheme?
  let buttonClass = isNeutral ? styles.neutral : ''
  if (selected) {
    buttonClass += ' ' + styles.selected
  }
  const w = width ? { width: width } : {}

  return (
    <button type='button'
      style={w}
      className={buttonClass}
      onClick={onClick}  >
      {!iconName || <i className={'fa fa-' + iconName} />}
      <span>{title}</span>
    </button>
  )
}