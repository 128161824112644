import React, { FC, useState, useEffect } from 'react'

const styles = require('./form.sass')

import { FormButtons, FormTitle } from '../'
import { Content, ISection } from '../../../services'

export interface IContentSectForm {
  title: string
  subtitle: string
  content: string
}

interface IProps {
  sect: ISection
  isAdding: boolean
  onSave: (frm: IContentSectForm) => void
  onDelete: () => void
  onCancel: () => void
}

export function setContentSect(sect: ISection, frm: IContentSectForm) {
  sect.title = frm.title
  sect.subtitle = frm.subtitle
  sect.content = frm.content
  return sect
}

export const ContentSectForm: FC<IProps> = props => {
  const sect = props.sect || Content.EmptySect

  const [_state, _setState] = useState<IContentSectForm>({
    title: sect.title,
    subtitle: sect.subtitle,
    content: sect.content || ''
  })

  useEffect(() => {
    const el = document.getElementById('title') as HTMLInputElement
    if (el) {
      el.focus()
      el.setSelectionRange(0, 0)
    }
    if (props.isAdding) {
      _setState(Content.EmptySect)
    }
  }, [props.isAdding])

  function _inputChange(e: any) {
    const el = e.target as HTMLInputElement;
    _setState(prevState => ({
      ...prevState, [el.name]: el.value
    }))
  }

  return (
    <div className={styles.form}>
      <div className={styles.body}>
        <label>
          <FormTitle title={_state.title} isAdding={props.isAdding} />
          <input type='text' name='title' id='title'
            value={_state.title} onChange={_inputChange} />
        </label>
        <label>
          Sub Title
          <input type='text' name='subtitle'
            value={_state.subtitle} onChange={_inputChange} />
        </label>
        <label>
          Content
            <textarea name='content' rows={24}
            value={_state.content} onChange={_inputChange} />
        </label>
      </div>
      <div className={styles.foot}>
        <FormButtons isAdding={props.isAdding}
          onSave={() => props.onSave(_state)}
          onDelete={props.onDelete}
          onCancel={props.onCancel} />
      </div>
    </div >
  )
}