import React, { FC, useState } from "react";

const styles = require('./list.sass')

import { ISection } from '../../../../services'
import { IContentActionData, ContentAction, setItemTitle, BlockItem } from "../";

interface IProps {
  sect: ISection
  data: IContentActionData
  onClick: (data: IContentActionData) => void
}

export const SectItem: FC<IProps> = ({ sect, data, onClick }) => {
  const [_hovering, _setHovering] = useState(false)
  const [_expand, _setExpand] = useState(false)

  function _click(action: ContentAction) {
    if (action === 'select') _setExpand(!_expand)
    if (action === 'addBlock') _setExpand(true)
    if (action === 'moveDown' || action === 'moveUp') _setExpand(false)

    onClick({
      action: action,
      sectID: sect.id,
      sectTitle: sect.title,
      blockID: 0,
      blockTitle: '',
      current: action === 'addBlock' ? 'block' : 'sect'
    })
  }

  return (
    <>
      <div className={sect.id === data.sectID ? styles.sectItemSelected : styles.sectItem}
        onMouseEnter={() => _setHovering(true)}
        onMouseLeave={() => _setHovering(false)}>

        <div className={styles.title}
          onClick={() => _click('select')}
          onMouseEnter={setItemTitle}>{sect.title}</div>
        {
          _hovering && <div className={styles.itemCommand}>
            <i className='fa fa-caret-up' title='move up sect'
              onClick={() => _click('moveUp')} />
            <i className='fa fa-caret-down' title='move down sect'
              onClick={() => _click('moveDown')} />
            <i className='fa fa-plus' title='add block'
              onClick={() => _click('addBlock')} />
            <i className='fa fa-trash-o' title='delete section'
              onClick={() => _click('delete')} />
          </div>
        }
      </div>
      {
        (_expand &&
          <div className={styles.blockItems}>
            {sect.blocks.map((block, i) => <BlockItem key={'block' + i}
              sect={sect}
              block={block}
              selected={sect.id === data.sectID && block.id === data.blockID}
              onClick={onClick} />)}
          </div>
        )
      }
    </>
  )
}