import React, { useState, useEffect } from 'react'

import { Button } from '../admin/button/button'
import { Header } from './header'
import { Contacts } from "../../services/contacts";

import './newsletter.sass'
import '../admin/forms/form.sass'

export function Unsubscribe() {
  const [err, setErr] = useState(false)
  const [success, setSuccess] = useState(false)

  const srv = new Contacts()
  let email: HTMLInputElement = null

  useEffect(() => {
    srv.load()
    email = document.getElementById('email') as HTMLInputElement
    if (!email) { return }
    email.focus()
  })

  function click() {
    const val = email.value.trim()
    if (srv.findByEmail(val)) {
      srv.deleteByEmail(val)
      setSuccess(true)
      setErr(false)
    }
    else {
      setErr(true)
      setSuccess(false)
    }
  }

  function getMessage() {
    if (err) {
      return (
        <div className='message' style={{ color: 'red' }}>Email does not exist. Please try again.</div>
      )
    }
    else if (success) {
      return (
        <div className='message'>You have been successfully removed.</div>
      )
    }
  }

  return (
    <div className='newsletter'>
      <Header title='UNSUBSCRIBE' />

      <div className="unsubscribe">
        {getMessage()}
        <div>Your Email Address</div>
        <input type='text' id='email' />
        <Button title='Remove all Correspondence' onClick={click} width={280} />
      </div>

    </div>
  )
}