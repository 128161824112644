import React, { useState } from 'react'
//import { useSpring, animated } from 'react-spring'

import {
  Button,
  SignInForm,
  Contact,
  Content,
} from './'

const styles = require('./admin.sass')

export type AdminApp = 'contact' | 'content'

interface IState {
  isAuth: boolean
  app: AdminApp
}

export function Admin() {
  const [_state, _setState] = useState<IState>({
    isAuth: false,
    app: 'content',
  })

  function _signIn() {
    sessionStorage['nti-admin'] = 'is-auth'
    _setState(prevState => ({ ...prevState, isAuth: true }))
  }

  function _getApp() {
    if (_isAuth()) {
      switch (_state.app) {
        case 'contact':
          return <Contact />
        case 'content':
          return <Content />
      }
    }
    else {
      return <SignInForm onSignIn={_signIn} />
    }
  }

  function _isAuth() {
    return _state.isAuth || sessionStorage['nti-admin'] === 'is-auth'
  }

  return (
    <div className={styles.admin}>
      <Header onNav={(app) => _setState(s => ({ ...s, app: app }))} />
      {_getApp()}
    </div>
  )
}

function Header({ onNav }) {
  const [app, setApp] = useState<AdminApp>('content')

  function _click(app: AdminApp) {
    setApp(app)
    onNav(app)
  }

  return (
    <div className={styles.header}>
      <span className={styles.logo} onClick={() => window.location.href = '/'}>NTI</span>
      <Button title='Contacts' selected={app === 'contact'} onClick={() => _click('contact')} />
      <Button title='Content' selected={app === 'content'} onClick={() => _click('content')} />
    </div>
  )
}