import React from 'react'
import { Button } from '../'
export function FormButtons({
  isAdding,
  onSave,
  onDelete,
  onCancel,
}) {
  return (<>
    <Button title='Cancel' iconName='times' isNeutral onClick={onCancel} />
    {isAdding || <Button title='Delete' iconName='trash-o' onClick={onDelete} />}
    <Button title='Save' iconName='floppy-o' onClick={() => onSave()} />
  </>)
}

export function FormTitle({ title, isAdding }) {
  return title.trim() === '' && !isAdding ?
    <span>Title<span style={{ color: 'red', fontSize: '.8rem', marginLeft: 12 }}>please enter a title</span></span> :
    <span>Title</span>
}