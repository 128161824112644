import React, { FC, useState } from 'react'

import { Button } from '../admin/button/button'
import './newsletter.sass'

export function Header({ title }) {

  return (
    <div className='newsletter-header'>
      <span className='logo'>NTI</span>
      <span className='title'>{title}</span>
      {/* <Button title='Contacts' selected={app === 'contact'} onClick={() => _click('contact')} />
      <Button title='Content' selected={app === 'content'} onClick={() => _click('content')} /> */}
    </div>
  )
}