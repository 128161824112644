// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}label{display:flex;flex-direction:column;margin-bottom:12px !important;font-size:1rem;font-weight:500}input{border:1px solid #cacfd6;padding:8px 0 8px 8px;margin:6px 0;font-size:.85rem;color:#424874;letter-spacing:.04rem;border-radius:3px}input:hover{border:1px solid #928dfc}input:focus{border:2px solid #928dfc;box-shadow:0 0 3px #928dfc;outline:none}textarea{border:1px solid #cacfd6;padding:8px 0 8px 8px;margin:6px 0 0 0;font-family:Arial,Helvetica,sans-serif;font-size:.85rem;color:#424874;letter-spacing:.04rem;line-height:1.1rem;border-radius:3px}textarea:hover{border:1px solid #928dfc}textarea:focus{border:2px solid #928dfc;box-shadow:0 0 3px #928dfc;outline:none}select{border:1px solid #cacfd6;padding:8px 0 8px 8px;margin:6px 0;font-size:.85rem;color:#424874;letter-spacing:.04rem;border-radius:3px}select:hover{border:1px solid #928dfc}select:focus{border:2px solid #928dfc;box-shadow:0 0 3px #928dfc;outline:none}._3HaLGacTbOqe75msYRhno8{display:flex;flex-direction:column;font-family:\"Roboto\",Arial,Helvetica,sans-serif;color:#5b599c}._3HaLGacTbOqe75msYRhno8 ._24ZE6lMsBqygPQjFv-O5e{display:flex;flex-direction:column}._3HaLGacTbOqe75msYRhno8 .xlJWD1tafaDgOQ_IV4dax{margin:16px 24px 0 0;text-align:right}._3HaLGacTbOqe75msYRhno8 ._1AuwaN0F4Rzz95giNSHVMC{color:red;font-size:.8rem;padding-left:12px}", ""]);
// Exports
exports.locals = {
	"form": "_3HaLGacTbOqe75msYRhno8",
	"body": "_24ZE6lMsBqygPQjFv-O5e",
	"foot": "xlJWD1tafaDgOQ_IV4dax",
	"alert": "_1AuwaN0F4Rzz95giNSHVMC"
};
module.exports = exports;
