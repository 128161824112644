import React from "react"

interface ITag {
  name: string
  start: number
  end: number
  text: string
  startHtml: string
  endHtml: string
}

function findTag(content: string): ITag {
  const start = content.indexOf('<')
  const startClose = content.indexOf('>')
  if (start === -1 || startClose === -1) return undefined

  let name = content.substring(start + 1, startClose)
  const startHtml = '<' + name + '>'
  if (name.startsWith('a')) name = 'a'
  const endHtml = '</' + name + '>'

  const end = content.indexOf(endHtml)
  if (end === -1) return undefined

  const text = content.substring(start + startHtml.length, end)

  return {
    name: name,
    start: start,
    end: end + endHtml.length,
    text: text,
    startHtml: startHtml,
    endHtml: endHtml,
  }
}


export function parseContent(content: string) {
  const els: JSX.Element[] = []

  let tag = findTag(content)
  if (!tag) return [<span key={'s0'}>{content}</span>]

  let i = 0
  while (tag) {
    // get text before the tag
    const txt = content.substring(0, tag.start)
    if (txt.trim() !== '') {
      els.push(<span key={'s1-' + i}>{txt} </span>)
    }

    switch (tag.name.toLowerCase()) {
      case 'b':
        els.push(<b key={'b-' + i}>{tag.text} </b>)
        break
      case 'a':
        let href = tag.startHtml.substring(tag.startHtml.indexOf('href') + 5, tag.startHtml.indexOf('>') - 1)
        els.push(<a key={'a-' + i} href={href} > {tag.text} </a>)
        break
      case 'p':
        els.push(<p key={'p-' + i}>{tag.text} </p>)
        break
      case 'h4':
        els.push(<h4 key={'h4-' + i}>{tag.text}</h4>)
    }

    content = content.substring(tag.end)
    tag = findTag(content)
    if (!tag && content.trim() !== '') {
      els.push(<span key={'s2-' + i}>{content} </span>)
    }

    i += 1
  }

  return els
}