// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}.CzNg_xZprFb-dWnkew9q5{height:100vh;font-family:\"Roboto\",arial,sans-serif !important}.CzNg_xZprFb-dWnkew9q5 ._3HxrktM50jP7m4IY1FftTL{display:flex;align-items:center;height:60px;box-sizing:border-box;background:#5b599c;color:#fff;padding:9px 24px}.CzNg_xZprFb-dWnkew9q5 ._3HxrktM50jP7m4IY1FftTL ._3OVMpLxP28FmfY9FzrzHn7{font-family:\"Tangerine\",arial,sans-serif;font-size:32px;font-weight:600;flex-grow:1;transition:color 1s;cursor:pointer}._1AicKMHA0Yucm7gYGpGwg5{display:flex;height:calc(100vh - 110px)}._1AicKMHA0Yucm7gYGpGwg5 ._3TlzFna3iJzH_2wBBYz2tL{height:100%;border-right:1px solid #eaeaea}._1AicKMHA0Yucm7gYGpGwg5 ._1NHP19o3BsqvJl6KOcMzFd{flex-grow:1;height:100%;padding:24px}._2OS0dj5g8Fs3pP8ztsve4X{display:flex;align-items:center;justify-content:center;width:100%;height:100%;font-size:4.2rem;color:#858585}._2OS0dj5g8Fs3pP8ztsve4X i{font-size:24rem;color:#eaeaea}._3lMNzkAXCaw77Quef_OW-a{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;font-size:12rem;color:#eaeaea}", ""]);
// Exports
exports.locals = {
	"admin": "CzNg_xZprFb-dWnkew9q5",
	"header": "_3HxrktM50jP7m4IY1FftTL",
	"logo": "_3OVMpLxP28FmfY9FzrzHn7",
	"body": "_1AicKMHA0Yucm7gYGpGwg5",
	"leftSide": "_3TlzFna3iJzH_2wBBYz2tL",
	"rightSide": "_1NHP19o3BsqvJl6KOcMzFd",
	"empty": "_2OS0dj5g8Fs3pP8ztsve4X",
	"loading": "_3lMNzkAXCaw77Quef_OW-a"
};
module.exports = exports;
