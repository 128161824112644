// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}body{height:100vh;font-family:\"Arapey\",arial,sans-serif;font-size:16px}.UTf2l6TQLsya1-yd0typY{height:100vh;font-family:\"Roboto\",arial,sans-serif !important}.UTf2l6TQLsya1-yd0typY .CJSrSd-sqFZBuMJq0FFHY{display:flex;align-items:center;height:60px;box-sizing:border-box;background:#5b599c;color:#fff;padding:9px 24px}.UTf2l6TQLsya1-yd0typY .CJSrSd-sqFZBuMJq0FFHY ._3iDKgWqOGZsNcLKkgcgNZf{font-family:\"Tangerine\",arial,sans-serif;font-size:32px;font-weight:600;flex-grow:1;transition:color 1s;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"admin": "UTf2l6TQLsya1-yd0typY",
	"header": "CJSrSd-sqFZBuMJq0FFHY",
	"logo": "_3iDKgWqOGZsNcLKkgcgNZf"
};
module.exports = exports;
