import React, { FC, useState, useEffect } from 'react'
import { useSpring } from 'react-spring'

const styles = require('./header.sass')

const navItems = [
  'Animal Communication',
  'Intuitive Readings',
  'Telephone Sessions',
  'About',
  'Contact',
];

const mobileNavWidth = '220px'

interface IProps {
  onLogoClick?: () => void
  isAdmin?: boolean
}

export const Header: FC<IProps> = ({ onLogoClick, isAdmin }) => {
  const [_className, _setClassName] = useState(styles.header)

  const [_scrollY, _setScrollY] = useSpring(() => ({
    y: 0, onFrame: props => window.scroll(0, props.y),
  }))

  const [_menuWidth, _setMenuWidth] = useSpring(() => ({
    width: '0px',
    onFrame: props => document.getElementById('mobileNav').style.width = props.width,
    onRest: () => {
      const nav = document.getElementById('mobileNav')
      if (nav.style.width === '0px') {
        nav.style.visibility = 'hidden'
      }
    }
  }))

  useEffect(() => {
    window.addEventListener('scroll', _handleScroll)
    return () => window.removeEventListener('scroll', _handleScroll)
  });

  function _handleScroll() {
    const topOffset = window.pageYOffset
    if (topOffset > 0) {
      _setClassName(styles.headerScroll)
    }
    else if (topOffset === 0) {
      _setClassName(styles.header)
      //onLogoClick()
    }
  }

  function _navClick(ev: any) {
    const target = ev.target as HTMLSpanElement
    const elementID = target.innerText.split(' ').join('_').toLowerCase()
    const sect = document.getElementById(elementID)
    _setMenuWidth({ width: '0px' })
    _setScrollY({ y: sect.offsetTop - 65 }) // header height
  }

  function _iconClick() {
    const nav = document.getElementById('mobileNav') as HTMLDivElement
    if (nav.style.visibility === 'visible') {
      _setMenuWidth({ width: '0px' })
    }
    else {
      nav.style.visibility = 'visible'
      _setMenuWidth({ width: mobileNavWidth })
    }
  }

  function _logoClick() {
    if (isAdmin) {
      window.location.href = '/'
      return;
    }
    _setScrollY({ y: 0 })
    onLogoClick()
  }

  return (
    <header className={_className}>
      <div className={styles.logo} onClick={_logoClick}>NTI</div>
      {
        isAdmin ?
          <div className={styles.adminTxt}>
            ADMIN
          </div>
          :
          <div className={styles.nav} onClick={_navClick}>
            {navItems.map((item, i) => <span key={i}>{item}</span>)}
          </div>
      }
      <i className='fa fa-bars fa-2x' onClick={_iconClick}></i>
      <div id='mobileNav' className={styles.mobileNav} onClick={_navClick}>
        {navItems.map((item, i) => <span key={i}>{item}</span>)}
      </div>
    </header>

  );
}