import React, { FC, useState, useEffect } from 'react'

const styles = require('./form.sass')

import { Button } from "../"

interface IState {
  username: string
  password: string
  invalid: boolean
}

interface IProps {
  onSignIn: () => void
}

export const SignInForm: FC<IProps> = ({ onSignIn }) => {
  const [_state, _setState] = useState<IState>({
    username: '',
    password: '',
    invalid: false
  })

  useEffect(() => {
    const el = document.getElementById('username') as HTMLInputElement
    if (el) {
      el.focus()
      el.setSelectionRange(0, 0)
    }
  }, [])

  function _inputChange(e: any) {
    const el = e.target as HTMLInputElement;
    _setState(prevState => ({
      ...prevState, [el.name]: el.value
    }))
  }

  function _signIn() {
    if (_state.username.trim() === 'admin' && _state.password.trim() === '232355') {
      onSignIn()
    }
    else {
      _setState(prevState => ({ ...prevState, invalid: true }))
    }
  }

  return (
    <div style={{
      width: 300,
      border: '1px solid rgba(91, 89, 156, .3)',
      borderRadius: 6,
      margin: '100px auto',
      padding: 24

    }} className={styles.form}>
      <div style={{
        fontFamily: "'Tangerine', arial, sans-serif",
        fontSize: '4rem',
        margin: '0 auto',
        paddingBottom: _state.invalid ? 0 : 12
      }}>Administration</div>
      {
        _state.invalid &&
        <div style={{
          textAlign: 'center',
          color: 'red',
          fontSize: '.8rem',
          margin: '0 0 6px 0'
        }}>wrong username or password</div>
      }
      <div className={styles.body}>
        <label>
          Username
          <input type='text' name='username' id='username'
            value={_state.username} onChange={_inputChange} />
        </label>

        <label>
          Password
          <input type='password' name='password'
            value={_state.password} onChange={_inputChange} />
        </label>

      </div>
      <div style={{ textAlign: 'center', marginTop: 12 }}>
        <Button title='Sign In' width={150} onClick={_signIn} />
      </div>
    </div >
  )
}
