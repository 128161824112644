import React, { FC, useState } from "react";

const styles = require('./list.sass')

import { IContentActionData, ContentAction, setItemTitle } from "../";
import { ISection, IBlock } from "../../../../services";

interface IProps {
  sect: ISection
  block: IBlock
  selected: boolean
  onClick: (data: IContentActionData) => void
}
export const BlockItem: FC<IProps> = ({ sect, block, selected, onClick }) => {
  const [_hovering, _setHovering] = useState(false)

  function _click(action: ContentAction) {
    onClick({
      action: action,
      sectID: sect.id,
      sectTitle: sect.title,
      blockID: block.id,
      blockTitle: block.title,
      current: 'block'
    })
  }

  function _getIconName() {
    switch (block.type) {
      case 'default':
        return 'paper-plane-o'
      case 'icon':
        return 'picture-o'
      case 'linkExpand':
        return 'link'
      case 'linkToSect':
        return 'link'
      case 'title':
        return 'header'
      case 'paragraph':
        return 'paragraph'
      case 'text-only':
        return 'align-justify'
    }
  }

  return (
    <>
      <div className={selected ? styles.blockItemSelected : styles.blockItem}
        onMouseEnter={() => _setHovering(true)}
        onMouseLeave={() => _setHovering(false)}>

        <div className={styles.title}
          onClick={() => _click('selectBlock')}
          onMouseEnter={setItemTitle}>
          <i className={'fa fa-' + _getIconName()} style={{ marginRight: 6 }} title={block.type + ' item'} />
          {block.title}
        </div>

        {
          _hovering && <div className={styles.itemCommand}>
            <i className='fa fa-caret-up' title='move up block'
              onClick={() => _click('moveUp')} />
            <i className='fa fa-caret-down' title='move down block'
              onClick={() => _click('moveDown')} />
            <i className='fa fa-trash-o' title='delete block'
              onClick={() => _click('deleteBlock')} />
          </div>
        }
      </div>
    </>
  )
}