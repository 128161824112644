import React from 'react'
import { AnimatedText } from '../animatedText/animatedText'

const styles = require('./promo.sass')

export function Promo() {
  return (
    <div className={styles.promo}>
      <AnimatedText text='Nurturing The Intuitive' />

      <div style={{ margin: '0 32px' }}>
        <img src='/assets/imgs/main.jpg' />
      </div>

      <h2 className={styles.mission}>facilitating personal growth and healing with people and pets</h2>
    </div>
  )
}